import "./PreProctoringScreenRound2.scss";
import blurLeftArrow from "../../../Assets/SVG/blueLeftArrow.svg";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Button,
  IconButton,
  Typography,
  Box,
  FormGroup,
  FormControl,
} from "@mui/material";

import { proctoringInstructions } from "../../../mockData/proctoringInstructions/round1";
import lightBulb from "../../../Assets/SVG/lightbulb.svg";
import { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "../../../Assets/SVG/camera.svg";
import ScreenShareIcon from "../../../Assets/SVG/screenRecording.svg";
import checkIcon from "../../../Assets/SVG/check.svg";
import Popup from "../../Common/popUp/popUp";
import crosssIcon from "../../../Assets/SVG/Close.svg";

export const PreProctoringScreenRound2 = ({
  setViewProctoringScreen,
  startRecordings,
  viewPretest,
  setViewPretest,
  setStartTest,
  resumeStatus,
  resumeStatusR2,
}) => {
  const [openPermissionsPopup, setOpenPermissionsPopup] = useState(false);
  const [openPhotoPopup, setOpenPhotoPopup] = useState(false);
  const [permissions, setPermissions] = useState({
    camera: false,
    screenRecording: false,
  });
  const [photo, setPhoto] = useState(null);

  const resetState = () => {
    setPermissions({
      camera: false,
      screenRecording: false,
    });
    setPhoto(null);
    setOpenPermissionsPopup(false);
    setOpenPhotoPopup(false);
  };

  const handleAgree = () => {
    setOpenPermissionsPopup(true);
  };

  const handlePermissionsClose = () => {
    resetState();
  };

  const handlePhotoClose = () => {
    resetState();
  };

  const handlePermissionsContinue = () => {
    if (permissions.camera && permissions.screenRecording) {
      setOpenPermissionsPopup(false);
      setOpenPhotoPopup(true);
    }
  };

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;

    if (name === "camera" && checked) {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setPermissions((prev) => ({ ...prev, camera: true }));
      } catch (err) {
        console.error(cameraAndMicrophoneError, err);
        alert(alertData1);
      }
    }

    if (name === "screenRecording" && checked) {
      try {
        setPermissions((prev) => ({ ...prev, screenRecording: true }));
      } catch (err) {
        console.error(screenRecordingError, err);
      }
    }

    if (!checked) {
      setPermissions((prev) => ({ ...prev, [name]: false }));
    }
  };

  return (
    <>
      <div className="PreProctoringScreenRound1">
        <BackButton setViewProctoringScreen={setViewProctoringScreen} />
        <ProctoringInstructions handleAgree={handleAgree} />
        <PermissionsPopup
          open={openPermissionsPopup}
          onClose={handlePermissionsClose}
          onContinue={handlePermissionsContinue}
          permissions={permissions}
          handleCheckboxChange={handleCheckboxChange}
        />
        <PhotoPopup
          open={openPhotoPopup}
          onClose={handlePhotoClose}
          photo={photo}
          setPhoto={setPhoto}
          startRecordings={startRecordings}
        />
      </div>
      {viewPretest && (
        <BeforeStartInstructions
          setViewPretest={setViewPretest}
          setStartTest={setStartTest}
          resumeStatus={resumeStatus}
          resumeStatusR2={resumeStatusR2}
        />
      )}
    </>
  );
};

const BackButton = ({ setViewProctoringScreen }) => {
  return (
    <div
      className="back-to-home"
      onClick={() => setViewProctoringScreen(false)}
    >
      <div>
        <img src={blurLeftArrow} alt="" />
      </div>
      <div>Back</div>
    </div>
  );
};

const ProctoringInstructions = ({ handleAgree }) => {
  return (
    <div className="details-container">
      <div variant="h6" className="heading2">
        Important points to consider:
      </div>
      <div className="details">
        {proctoringInstructions.instructions.map((instruction, index) => (
          <div key={index} className="each-item">
            <div>
              <img src={lightBulb} alt="" />
            </div>
            <div className="listitem">
              <span dangerouslySetInnerHTML={{ __html: instruction }} />
            </div>
          </div>
        ))}
      </div>
      <Button
        variant="contained"
        className="agree"
        onClick={handleAgree}
        sx={{ ...buttonStyle, backgroundColor: "#062B6E", color: "#FFF" }}
      >
        I agree
      </Button>
    </div>
  );
};

const PermissionsPopup = ({
  open,
  onClose,
  onContinue,
  permissions,
  handleCheckboxChange,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          position: "absolute",
          top: "10%",
          margin: 0,
          maxWidth: "400px",
          width: "100%",
          padding: "0 20px 20px 0",
        },
      }}
    >
      <DialogTitle>
        <Typography style={{ marginTop: "20px" }}>{accessHeading}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControl component="fieldset">
            <Box display="flex" alignItems="center">
              <img
                src={CameraAltIcon}
                alt="Camera icon"
                style={{ marginRight: 8 }}
              />
              <Typography>Access your camera and microphone</Typography>
              <Checkbox
                checked={permissions.camera}
                onChange={handleCheckboxChange}
                name="camera"
                color="primary"
                style={{ marginLeft: "auto" }}
              />
            </Box>
          </FormControl>
          <FormControl component="fieldset">
            <Box display="flex" alignItems="center">
              <img
                src={ScreenShareIcon}
                alt="Screen recording icon"
                style={{ marginRight: 8 }}
              />
              <Typography>Allow screen recording</Typography>
              <Checkbox
                checked={permissions.screenRecording}
                onChange={handleCheckboxChange}
                name="screenRecording"
                color="primary"
                style={{ marginLeft: "auto" }}
              />
            </Box>
            <Typography variant="body2" style={{ marginTop: 8, color: "#777" }}>
              Please select "Entire screen" when prompted to allow full screen
              recording.
            </Typography>
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className="block-button"
          sx={{
            ...blockButtonStyle,
            "&:hover": { ...blockButtonStyle },
            marginRight: "20px",
          }}
        >
          Block
        </Button>
        <Button
          onClick={onContinue}
          variant="contained"
          disabled={!permissions.camera || !permissions.screenRecording}
          sx={{
            ...continueButtonStyle(
              permissions.camera,
              permissions.screenRecording
            ),
            "&:hover": {
              ...continueButtonStyle(
                permissions.camera,
                permissions.screenRecording
              ),
            },
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PhotoPopup = ({ open, onClose, photo, setPhoto, startRecordings }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    let stream;
    if (open && !photo) {
      // Access the camera
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          stream = mediaStream;
          const video = videoRef.current;
          video.srcObject = stream;
        });

      // Automatically take a photo after 15 seconds
      const timer = setTimeout(() => {
        takePhoto();
      }, 15000);

      return () => {
        clearTimeout(timer);
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      };
    }
  }, [open, photo]);

  const takePhoto = () => {
    const video = videoRef.current;
    if (!video) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const imageUrl = URL.createObjectURL(blob);
      setPhoto({ blob, url: imageUrl }); // Store the photo as a Blob and its object URL
    }, "image/png");

    // Stop all video tracks
    video.srcObject.getTracks().forEach((track) => track.stop());
  };

  const handleRetake = () => {
    setPhoto(null);
    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      const video = videoRef.current;

      // Stop the previous stream if any
      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => track.stop());
      }

      video.srcObject = stream;
    });
  };

  const handleSendPhoto = async () => {
    try {
      const candName = localStorage.getItem("email");

      if (!candName) {
        throw new Error("Candidate name is missing");
      }

      if (!photo) {
        throw new Error("Photo is missing");
      }

      // Send photo to the specified endpoint as FormData
      const formData = new FormData();
      formData.append("image", photo.blob, "photo.png"); // Use the Blob and provide a filename

      const response = await fetch(
        `/candidate/round2/proctoring/upload-photo?candName=${encodeURIComponent(
          candName
        )}&round=round2`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to upload photo");
      }

      // console.log("Photo uploaded successfully");

      // Close the photo capture dialog and start recordings
      onClose();
      await startRecordings();
    } catch (error) {
      console.error("Error submitting photo:", error.message);
    }
  };

  const handleSubmit = async () => {
    // Logic to submit the photo and start the test
    handleSendPhoto();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          position: "absolute",
          top: "10%",
          margin: 0,
          maxWidth: "500px",
          width: "100%",
          padding: "0 20px 20px 0",
        },
      }}
    >
      <DialogTitle>
        <Typography style={verifyId}>Verify your identity</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            height: "200px",
            backgroundColor: "#D8D8D8",
            borderRadius: "8px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          {photo ? (
            <img
              src={photo.url}
              alt="Captured"
              style={{ width: "", height: "100%", borderRadius: "8px" }}
            />
          ) : (
            <video
              ref={videoRef}
              style={{ width: "100%", height: "100%", borderRadius: "8px" }}
              onLoadedMetadata={() => videoRef.current.play()}
            />
          )}
        </Box>
        {!photo ? (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: takePhotoConstant }}
          />
        ) : (
          <Box
            sx={{ marginTop: "20px", display: "flex", alignItems: "center" }}
          >
            <img
              src={checkIcon}
              alt="Checkmark"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            <Typography variant="body1" component="span" style={photoTaken}>
              Photo taken
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {photo ? (
          <>
            <Button
              onClick={handleRetake}
              variant="contained"
              sx={{
                backgroundColor: "#777",
                color: "#FFF",
                padding: "12px 24px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#777",
                },
                marginRight: "20px",
              }}
            >
              Retake Photo
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                backgroundColor: "#062B6E",
                color: "#FFF",
                padding: "12px 24px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#062B6E",
                },
              }}
            >
              Submit
            </Button>
          </>
        ) : (
          <Button
            onClick={takePhoto}
            variant="contained"
            sx={{
              backgroundColor: "#062B6E",
              color: "#FFF",
              padding: "12px 24px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#062B6E",
              },
            }}
          >
            Take Photo
          </Button>
        )}
      </DialogActions>
      <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        width="640"
        height="480"
      ></canvas>
    </Dialog>
  );
};

const blockButtonStyle = {
  borderRadius: "8px",
  border: "1px solid #777",
  color: "#777",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  padding: "12px 24px",
};

const continueButtonStyle = (camera, screen) => {
  return {
    borderRadius: "8px",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    padding: "12px 24px",
    background: !camera || !screen ? "#D8D8D8" : "#062B6E",
  };
};

const buttonStyle = {
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  padding: "12px 24px",
  "&:hover": {
    backgroundColor: "inherit",
    color: "inherit",
  },
};

const verifyId = {
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
  marginTop: "20px",
};
const photoTaken = {
  color: "#0BB762",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
};

const BeforeStartInstructions = ({
  setViewPretest,
  setStartTest,
  resumeStatus,
  resumeStatusR2,
}) => {
  const handleStartClick = async () => {
    await resumeStatusR2();
    setViewPretest(false);
    setStartTest(true);
  };
  return (
    <Popup>
      <div className="otpVerificationContainer">
        <div className="close">
          <img
            src={crosssIcon}
            alt="close"
            onClick={() => setViewPretest(false)}
          />
        </div>
        <div className="note">Note</div>
        <div className="noteMessage">
          Once the test begins, it must be completed in one session without the
          option to pause or resume later option.
        </div>
        <div className="beforeStartButtons">
          <button onClick={() => setViewPretest(false)}>Close</button>
          <button onClick={() => handleStartClick()}>
            {resumeStatus?.numOfQuestionsAttempted > 0
              ? "Resume"
              : "Start the test"}
          </button>
        </div>
      </div>
    </Popup>
  );
};

const alertData1 = `Camera and microphone permissions are required. Please allow access from your browser settings.

To do this:
1. Click on the lock icon next to the URL in your browser.
2. Find "Camera" and "Microphone" in the permissions list.
3. Change the permission to "Allow".
4. Refresh the page.`;

const cameraAndMicrophoneError = "Camera and microphone permission denied:";
const screenRecordingError = "Screen recording permission denied:";
const takePhotoConstant = `The photo will be used during your hiring process. We will take your photo automatically after <span style="font-weight: bold;">15 seconds</span>.`;
const accessHeading = `"Zeero" would like to`;
