import React, { useRef, useState } from "react";
import { Button, Typography, Box } from "@mui/material";

function EyeProctoring() {
    const webcamRef = useRef(null);
    const [statusMessage, setStatusMessage] = useState("");
    const [isWebcamStarted, setIsWebcamStarted] = useState(false);

    let websocket = null;
    let webcamStream = null;
    let frameInterval = null;
    const uri = "ws://localhost:8765";

    const log = (message) => {
        console.log(message);
    };

    async function startWebcam() {
        try {
            // Open WebSocket connection
            websocket = new WebSocket(uri);

            websocket.onopen = () => {
                log("Connected to WebSocket server.");
            };

            websocket.onmessage = (event) => {
                const result = event.data === "1" ? "Centered" : "Misaligned";
                log(`Received analysis result: ${result}`);
                if (result === "Misaligned") {
                    setStatusMessage("Misaligned");
                    setTimeout(() => setStatusMessage(""), 4000); // Clear message after 4 seconds
                }
            };

            websocket.onerror = (event) => {
                log(`WebSocket error: ${event.message}`);
            };

            websocket.onclose = () => {
                log("WebSocket connection closed.");
            };

            // Access webcam
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            webcamStream = stream;
            if (webcamRef.current) {
                webcamRef.current.srcObject = stream; // Assign stream to video element
            }

            // Capture and send frames
            const videoTracks = stream.getVideoTracks();
            const imageCapture = new ImageCapture(videoTracks[0]);

            frameInterval = setInterval(async () => {
                try {
                    const frame = await imageCapture.grabFrame();
                    const canvas = document.createElement("canvas");
                    canvas.width = frame.width;
                    canvas.height = frame.height;

                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(frame, 0, 0, frame.width, frame.height);

                    // Convert canvas to image data
                    canvas.toBlob(
                        (blob) => {
                            if (websocket.readyState === WebSocket.OPEN) {
                                websocket.send(blob);
                                log("Frame sent to server.");
                            }
                        },
                        "image/jpeg"
                    );
                } 
                catch (err) {
                    log(`Error capturing frame: ${err?.message}`);
                }
            }, 1000 / 30); // 30 FPS

            setIsWebcamStarted(true);
        } 
        catch (err) {
            log(`Error accessing webcam: ${err?.message}`);
        }
    }

    const stopWebcam = () => {
        if (webcamStream) {
            const tracks = webcamStream.getTracks();
            tracks.forEach((track) => track.stop());
            webcamStream = null;
            if (webcamRef.current) {
                webcamRef.current.srcObject = null;
            }
        }

        if (websocket) {
            websocket.close();
            websocket = null;
        }

        if (frameInterval) {
            clearInterval(frameInterval);
            frameInterval = null;
        }

        log("Webcam and WebSocket stopped.");
        setIsWebcamStarted(false);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 4 }}>
            <video
                ref={webcamRef} // Use webcamRef for React
                autoPlay
                playsInline
                style={{ width: "600px", height: "400px", border: "1px solid #ccc" }}
            ></video>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={startWebcam}
                    disabled={isWebcamStarted}
                >
                    Start Webcam
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={stopWebcam}
                    disabled={!isWebcamStarted}
                >
                    Stop Webcam
                </Button>
            </Box>
            {statusMessage && (
                <Typography
                    variant="h6"
                    sx={{
                        color: "red",
                        mt: 2,
                        animation: "fade-in-out 4s ease-in-out",
                    }}
                >
                    {statusMessage}
                </Typography>
            )}
        </Box>
    );
}

export default EyeProctoring;
