import React, { useState } from "react";

const LiveTranscription = () => {
  const [isListening, setIsListening] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [speaker, setSpeaker] = useState("Speaker 1");
  const [error, setError] = useState("");

  let recognition;
  if ("webkitSpeechRecognition" in window || "SpeechRecognition" in window) {
    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";
  } else {
    recognition = null;
  }

  const handleStart = () => {
    if (!recognition) {
      setError("Speech Recognition API is not supported in this browser.");
      return;
    }

    setIsListening(true);
    setError("");
    recognition.start();

    recognition.onresult = (event) => {
      const interimTranscript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join(" ");

      setConversation((prev) => [
        ...prev,
        { speaker, transcript: interimTranscript },
      ]);
    };

    recognition.onerror = (event) => {
      setError(`Error: ${event.error}`);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  const handleStop = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  };

  const handleDownload = () => {
    const fileContent = conversation
      .map((entry) => `${entry.speaker}: ${entry.transcript}`)
      .join("\n");
    const blob = new Blob([fileContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "conversation.txt";
    link.click();
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Two-Person Live Transcription</h1>
      <p>{error}</p>
      <div>
        <button onClick={handleStart} disabled={isListening}>
          Start Listening
        </button>
        <button onClick={handleStop} disabled={!isListening}>
          Stop Listening
        </button>
        <select
          value={speaker}
          onChange={(e) => setSpeaker(e.target.value)}
          disabled={isListening}
        >
          <option value="Speaker 1">Speaker 1</option>
          <option value="Speaker 2">Speaker 2</option>
        </select>
      </div>
      <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc" }}>
        <h2>Conversation:</h2>
        <ul>
          {conversation.map((entry, index) => (
            <li key={index}>
              <strong>{entry.speaker}:</strong> {entry.transcript}
            </li>
          ))}
        </ul>
      </div>
      <button onClick={handleDownload} style={{ marginTop: "20px" }}>
        Download Conversation
      </button>
    </div>
  );
};

export default LiveTranscription;
