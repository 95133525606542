import whatWeOfferBackground from "../Assets/home_client/2.webp";
import icon1 from "../Assets/home_client/new-icons/1.svg";
import icon2 from "../Assets/home_client/new-icons/2.svg";
import icon3 from "../Assets/home_client/new-icons/3.svg";
import icon4 from "../Assets/home_client/new-icons/4.svg";
import icon5 from "../Assets/home_client/new-icons/5.svg";
import icon6 from "../Assets/home_client/new-icons/6.svg";
import ai_robo from "../Assets/home_client/new-icons/ai_robo.webp";
import tech_back from "../Assets/home_client/new-icons/tech_back.webp";
import bg from "../Assets/home_client/new-icons/BG.webp";
import V1_Robot_Motion from "../Assets/home_client/new-icons/V1_Robot_Motion.gif";
import V2_Robot_Motion from "../Assets/home_client/new-icons/chatBotVideo.mp4";
import V2_Robot_Motion_Mobile from "../Assets/home_client/new-icons/chatBotVideoMobile.mp4";
import seamlessIcon1 from "../Assets/home_client/new-icons/seamless/1.svg";
import seamlessIcon2 from "../Assets/home_client/new-icons/seamless/2.svg";
import seamlessIcon3 from "../Assets/home_client/new-icons/seamless/3.svg";
import seamlessIcon4 from "../Assets/home_client/new-icons/seamless/4.svg";
import animationIcon from "../Assets/home_client/new-icons/animation.svg";

import seamlessImage from "../Assets/home_client/new-icons/seamless1Dummy.png";
import seamlessImage2 from "../Assets/home_client/new-icons/seamless/seamless2Dummy.png";
import seamlessImage3 from "../Assets/home_client/new-icons/seamless/seamless3Dummy.png";
import seamlessImage4 from "../Assets/home_client/new-icons/seamless/seamless4Dummy.png";
// import bannerVideo from "../Assets/home_client/new-icons/bannerVideo.mp4";
// import bannerVideo from "../Assets/home_client/new-icons/bannervideo2.mp4";
import bannerVideo from "../Assets/home_client/new-icons/bannervideo3.mp4";
import bannerVideoMobile from "../Assets/home_client/new-icons/mobileLandingVideo.mp4"
import confession1 from "../Assets/home_client/new-icons/confession1.png";
import confession2 from "../Assets/home_client/new-icons/confession2.png";
import confession3 from "../Assets/home_client/new-icons/confession3.png";
import confession4 from "../Assets/home_client/new-icons/confession4.png";

export const clientPageData = {
  banner: {
    line1: "Connect with best developers for your projects",
    line2:
      "Access top-tier developers globally, customized to fit your unique project needs. Build, scale, and succeed with Zeero’s trusted talent pool.",
    buttonText: "Get Started",
    buttonUrl: "/signup-client",
    videoUrl: bannerVideo,
    videoUrlMobile:bannerVideoMobile
    // videoUrl: "your-video-url.mp",
  },

  whatZeeroOffersData: {
    title: "What Zeero offers?",
    backgroundImageUrl: whatWeOfferBackground, // Replace with actual background image URL
    offers: [
      {
        icon: icon1,
        title: "Access to top talent",
        desc: "Access top-notch software developers on-demand for your projects. Our developers undergo extensive tests, project submissions, and interviews, ensuring their expertise in the industry. ",
      },
      {
        icon: icon2,
        title: "Flexibility and scalability",
        desc: "Whether you prefer short-term contracts, long-term partnerships, remote or onsite work arrangements, Zeero provides customizable solutions for maximum flexibility and scalability. ",
      },
      {
        icon: icon3,
        title: "Cost-effective solutions",
        desc: "Save time and resources with Zeero's efficient recruitment and onboarding process allowing businesses to focus on driving innovation and growth. ",
      },
      {
        icon: icon4,
        title: "Strategic partnership",
        desc: "We go beyond simply connecting you with talent. We're your strategic partner, supporting you as a solution to the challenges you face while resource planning.",
      },
      {
        icon: icon5,
        title: "Diverse and inclusive teams",
        desc: "Build stronger, more inclusive teams with Zeero's global talent pool, accessing a wide range of perspectives and experiences to promote creativity, innovation, and collaboration.",
      },
      {
        icon: icon6,
        title: "Project management & 24X7 support",
        desc: "Our support staff is always on hand to ensure you get exactly what you want for your projects. We provide you with 24x7 support and guidance you need to succeed. ",
      },
    ],
  },

  zeeroAIPlatformData: {
    // backgroundImageUrl: ai_robo, // Replace with actual background image URL
    backgroundImageUrl: V2_Robot_Motion, // Replace with actual background image URL
    backgroundImageUrlMobile: V2_Robot_Motion_Mobile,
    line1: "Discover the power of Zeero’s AI platform",
    line2:
      "Experience Zeero’s versatile AI-powered hiring tool for swiftly landing top-tier & rigorously vetted engineers.",
    buttonText: "Hire Top Talent",
  },
  topTechnologiesData: {
    backgroundImageUrl: tech_back, // Replace with your background image URL
   

    title: "Zeero leads you to the top technologies",
    techFields: [
      {
        name: "Python",
        gradient: `linear-gradient(
    45deg, 
    #17B3A9, 
    #0945DF
);`,
      },
      {
        name: "Java",
        gradient: `linear-gradient(
          45deg, 
          #D2428F, 
          #B842C5
      );`,
      },
      {
        name: "Node JS",
        gradient: `linear-gradient(
          45deg, 
          #8374D9, 
          #C55273
      );`,
      },
      {
        name: "Javascript",
        gradient: `linear-gradient(
          45deg, 
          #6BCFFA, 
          #4473F6
      );`,
      },
      {
        name: "React JS",
        gradient: `linear-gradient(
          45deg, 
          #17B3A9, 
          #0945DF
      );`,
      },
      {
        name: "SQL",
        gradient: `linear-gradient(
          45deg, 
          #D2428F, 
          #B842C5
      );`,
      },
      {
        name: "Angular JS",
        gradient: `linear-gradient(
          45deg, 
          #8374D9, 
          #C55273
      );`,
      },
      {
        name: "Bitbucket",
        gradient: `linear-gradient(
          45deg, 
          #6BCFFA, 
          #4473F6
      );`,
      },
    ],
  },
  testimonialsSliderData: {
    backgroundImageUrl: bg, // Replace with actual background image URL
    title: "Don’t just take our word for it",
    testimonials: [
      {
        title: "Exceptionally streamlined",
        desc: ` Zeero’s assessment process was rigorous but fair. 
It gave me the opportunity to showcase both my technical and soft skills, and I appreciated the transparency and feedback provided throughout the process. The experience helped me grow as a developer, and I would highly recommend it to anyone looking for a challenging yet fulfilling interview process.`,
        authorName: "Tochukwu Udochukwu",
        authorTitle: "Frontend Developer",
        authorImage: confession1, // Replace with actual image URL
      },
      {
        title: "Highly recommended",
        desc: `After signing up on Zeero, I embarked on an intense assessment that lasted over two hours. The experience was both challenging and rewarding, and I successfully passed this stage, moving forward to the next step—an HR interview. The interaction during the interview was engaging and insightful.`,
        authorName: "Chukwuebuka Nwaturuba",
        authorTitle: "Backend Developer",
        authorImage: confession2,
      },
      {
        title: "Great experience",
        desc: `The recruitment process was rigorous but offered a balanced and fair assessment of my technical and interpersonal skills. It was an incredible learning experience that contributed to my growth as a professional. Also, this allowed me to share insights into my thought processes, coding practices, and the depth of my problem-solving skills.`,
        authorName: "Vishnu Vardhan Chakka",
        authorTitle: "Backend Developer",
        authorImage: confession3,
      },
      {
        title: "Pleasant experience",
        desc: `The entire process was intensive yet seamless. What stood out to me was the constant human interaction I experienced with the Zeero team, something uncommon in other recruitment platforms. The staff was highly responsive and always available whenever I reached out. I highly recommend Zeero for engineers looking for a transparent and recruitment process.`,
        authorName: "Jatin Verma",
        authorTitle: "Frontend Developer",
        authorImage: confession4,
      },
    ],
  },
  seamlessPartnershipData: {
    part1Title: "Experience effortless collaboration with Zeero",

    part2Items: [
      {
        image: seamlessIcon1, // Dummy image URL
        title: "Top talent",
        desc: "We connect you with skilled developers who match your project needs.",
      },
      {
        image: seamlessIcon2, // Dummy image URL
        title: "Tailored teams",
        desc: "Build a team specifically suited to your  project’s requirements.",
      },
      {
        image: seamlessIcon3, // Dummy image URL
        title: "Flexible solutions",
        desc: "Find the right developers for your preferred work  environment, whether on-site, remote, or hybrid.",
      },
      {
        image: seamlessIcon4, // Dummy image URL
        title: "Timely delivery",
        desc: "Ensure your project stays on track with  developers ready to start when you are.",
      },
    ],

    part3Images: [
      {
        image: seamlessImage, // Dummy image URL
        title: "Backend Developer",
      },
      {
        image: seamlessImage2, // Dummy image URL
        title: "Full Stack Developer",
      },
      {
        image: seamlessImage3, // Dummy image URL
        title: "Data Scientist",
      },
      {
        image: seamlessImage4, // Dummy image URL
        title: "Frontend Developer",
      },
    ],

    part4Title: "Ready to build your ideal team?",
    part4ButtonText: "Hire Now",
  },
  hireDeveloperData: {
    title: "How to hire a developer?",
    description: "Get started in just 5 steps...",
    animationIcon: animationIcon,
    steps: [
      {
        number: 1,
        title: "Define Your Needs",
        description:
          "Tell us about your project's goals, timeline, and skill requirements. Our Client Success Manager will work with you to ensure a clear understanding.",
      },
      {
        number: 2,
        title: "Review Candidates",
        description:
          "We'll present you with a list of pre-vetted engineers with the skills and experience needed for your project.",
      },
      {
        number: 3,
        title: "Conduct Interviews",
        description:
          "Meet with the top candidates and assess their technical and cultural fit for your project.",
      },
      {
        number: 4,
        title: "Make Your Choice",
        description:
          "Select the right developer for your needs and get ready to start working together.",
      },
      {
        number: 5,
        title: "Begin the Project",
        description:
          "Once the contract is in place, your developer will start working on your project.",
      },
    ],
  },
};
